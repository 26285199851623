import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Simplicity",
  "date": "2014-12-09T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "http://shopify.com"
      }}>{`We`}</a>{` were shooting an internal video this week and I got asked to talk about one of our core values: simplicity.`}</p>
    <p>{`Everyone seems to think simplicity that things start simple and get more and more complex over time, but it’s actually backwards. In my experience things start being fairly complex (look around you, the status quo isn’t simple things) and it’s by a relentless process of distillation and iteration that you arrive to the core of an idea.`}</p>
    <p>{`Take this idea and strip out everything that isn’t essential to the thing you’re doing. Everyone wants to use things that have just the right amount of complexity, not too much, not too little.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      